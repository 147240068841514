(function () {
	document.addEventListener("DOMContentLoaded", init);
	function init() {
		
		var videoElement = $("#hero__video")[0];

		$(".btn-ctrl.btn-close").on('click', () => {
			$.magnificPopup.close();
		});

		$(".btn-ctrl.btn-play-pause").on('click', (e) => {
			var el = $(e.target);
			if(el.hasClass("playing")){
				videoElement.pause();
			}
			else {
				videoElement.play();
			}

			$(".btn-ctrl.btn-play-pause").toggleClass("playing");
		});

		/*$(videoElement).on('ended', () => {
			var el = $(".btn-ctrl.btn-play-pause");
			el.removeClass("playing");
		});*/

		$(videoElement).on('pause', () => {			
			var el = $(".btn-ctrl.btn-play-pause");
			el.removeClass("playing");
		});

		$(videoElement).on('playing', () => {
			var el = $(".btn-ctrl.btn-play-pause");
			el.addClass("playing");
		});

		$(".btn-ctrl.btn-fullscreen").on('click', () => {
			if (videoElement.requestFullscreen) {
				videoElement.requestFullscreen();
			} 
			else if (videoElement.mozRequestFullScreen) {
				videoElement.mozRequestFullScreen();
			} 
			else if (videoElement.webkitRequestFullscreen) {
				videoElement.webkitRequestFullscreen();
			} 
			else if (elem.msRequestFullscreen) { 
				videoElement.msRequestFullscreen();
			}
		});

		$(".watch-video").on('click', () => {
			
			var popupWrapper = $("#popup__wrapper_video");
			var staticWrapper = $(".video__container");
			var videoWrapper = $(".the_pidor");

			popupWrapper.append(videoWrapper);

			$.magnificPopup.open({
				items: {
					src: popupWrapper
				},
				type: 'inline',
				closeOnBgClick: true,
				modal: false,
				//mainClass: 'mfp-fade',
				removalDelay: 300,
				fixedContentPos: true,
				fixedBgPos: true,
				overflowY: "auto",
				callbacks: {
					open: () => {
						videoElement.currentTime = 0;
						videoElement.muted = false;
						videoElement.loop = false;
						if(videoElement.paused) {
							$(".btn-ctrl.btn-play-pause").removeClass("playing");
						}
						else {
							$(".btn-ctrl.btn-play-pause").addClass("playing");
						}
					},
					beforeClose: () => {
						videoElement.muted = true;
						videoElement.loop = true;
					},
					afterClose: () => {
						staticWrapper.append(videoWrapper);
						if(videoElement.paused){
							videoElement.play();
						}
					}
				}
			});
		});
	}

})();
